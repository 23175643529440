import React from "react";
import s from "./ErrorInvalidTokenOrBlock.module.css";
import logo from "../../assets/icons/logo/goiteens_logo.svg";

const ErrorInvalidTokenOrBlock = () => {
  return (
    <div className={s.container}>
      <img width="90" height="30" src={logo} alt="logo" />
      <p className={s.text}>Не валидная ссылка.</p>
    </div>
  );
};

export default ErrorInvalidTokenOrBlock;
