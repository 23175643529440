import React, { Component, createRef } from "react";
// компоненты из библиотек:
import { ToastContainer, toast } from "react-toastify";
import { Controlled as CodeMirror } from "react-codemirror2";
// cтили:
import styles from "./MainPage.module.css";
import "react-toastify/dist/ReactToastify.css";
// компоненты созданные:
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ButtonHideTheory from "../../components/ButtonHideTheory/ButtonHideTheory";
import TheoryAndTaskOrLayout from "../../components/TheoryAndTaskOrLayout/TheoryAndTaskOrLayout";
import FrameComponent from "../../components/FrameComponent/FrameComponent";
import codeMirrorCssSettings from "../../utils/codeMirrorCssSettings";
import TaskButtons from "../../components/TaskButtons/TaskButtons";
import ResultModal from "../../components/ResultModal/ResultModal";
import CongratsModal from "../../components/CongratsModal/CongratsModal";
import ErrorStub from "../../components/ErrorStub/ErrorStub";
import ErrorInvalidTokenOrBlock from "../../components/ErrorInvalidTokenOrBlock/ErrorInvalidTokenOrBlock";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import BrowserResultModal from "../../components/BrowserResultModal/BrowserResultModal";
import ErrorServerStub from "../../components/ErrorServerStub/ErrorServerStub";
import ModalUnresolvedDays from "../../components/ModalUnresolvedDays/ModalUnresolvedDays";
import Loader from "../../components/Loader/Loader";
import ModalFinishGiveArchive from "../../components/ModalFinishGiveArchive/ModalFinishGiveArchive";
import ModalAutotranslator from "../../components/ModalAutotranslator/ModalAutotranslator";
// прочие вспомогательные инструменты:
import * as API from "../../services/api";
// стили для codemirror
import "codemirror/lib/codemirror.css";
import "../../utils/materialCodeMirror.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/htmlmixed/htmlmixed";

const timerDelayInMs = 600000;
// const timerDelayInMs = 60;

let shouldAvailabilityAutotranslator = false;
const waitTimeInMs = 1000;
let availabilityAutotranslatorTimer = waitTimeInMs;
let timerAvailabilityAutotranslatorId;

class MainPage extends Component {
  state = {
    currentTaskId: "",
    blockTasksArr: "",
    token: "",
    valueHTML: "",
    valueCSS: "",
    htmlDescription: "",
    metadata: "",
    passed: "", // выполнена ли текущая задача или нет
    blockIndex: 0, // это номер дня марафоне в хедере и в поздравительной модалке
    // для модального окна с результатами:
    isResultModalOpen: false,
    successConditions: null,
    failedConditions: null,
    taskSuccessfullyPassed: null,
    // появление пропадание теории с заданием:
    isTheoryAndTaskShow: true,
    // модальное окно с поздравлениями:
    isCongratsModalOpen: false,
    // для отображения результата взаимодействия html и css:
    resultView: { __html: "" },
    // время старта для таймера:
    timerEndTime: Date.now() + timerDelayInMs,
    // показываем ли таймер на текущем вопросе:
    showTimer: true,
    // показать компонент ошибки (не корректная ссылка или блок в query parametrs строки или юзер не участник марафона):
    errorShow: false,
    // показать компонент ошибки когда токен или блок не валиден:
    errorInvalidTokenOrBlock: false,
    // для фиксации времени прохождения пользователем текущего вопроса:
    startTime: null,
    showBrowserResultModal: false,
    // буль на случай когда лежит сервер
    isServerError: false,
    // для модальных окон после 5ти дней о том есть ли нерешенные задания в каких то днях или марафон полностью завершен
    nonFinishedDays: null,
    finishedLastDay: false,
    passedAllTasks: false,
    // лоадер
    // showLoader: false,
    showModalAutotranslator: false,
  };

  theoryAndTaskRef = createRef();
  // refs for translateModal:
  headerTextLogoRef = createRef();
  headerTextTaskRef = createRef();
  footerTextRef = createRef();

  componentDidMount() {
    const { location } = this.props;
    const { clientWidth } = this.props;
    if (location.search && clientWidth > 900) {
      const token = new URLSearchParams(location.search).get("token");
      const block = new URLSearchParams(location.search).get("block");
      this.getCurrentBlockAndCurrentTask(token, block);
    } else {
      this.setState({ errorShow: true });
    }

    // checkAvailabilityAutotranslator logic - start
    timerAvailabilityAutotranslatorId = setInterval(() => {
      if (shouldAvailabilityAutotranslator) {
        availabilityAutotranslatorTimer -= 100;

        if (availabilityAutotranslatorTimer <= 0) {
          shouldAvailabilityAutotranslator = false;
          availabilityAutotranslatorTimer = waitTimeInMs;

          // monitoring availability autotranslator:
          this.checkAvailabilityAutotranslator();
        }
      }
    }, 100);
    // checkAvailabilityAutotranslator logic - end

    // monitoring availability autotranslator - start
    setTimeout(() => {
      this.checkAvailabilityAutotranslator();
    }, 2000);
    // monitoring availability autotranslator - end
  }

  componentDidUpdate(prevProps, prevState) {
    const { valueHTML, valueCSS, currentTaskId } = this.state;
    const { clientWidth } = this.props;
    if (
      (prevState.valueCSS !== valueCSS && clientWidth > 900) ||
      (prevState.valueHTML !== valueHTML && clientWidth > 900)
    ) {
      this.setState(
        {
          resultView: { __html: valueHTML },
        },
        () => this.addEventPreventDefaultForLinkInResultContainer()
      );
    }

    const { blockTasksArr } = this.state;
    if (
      prevState.clientWidth !== clientWidth &&
      prevState.clientWidth < 900 &&
      blockTasksArr === ""
    ) {
      const { location } = this.props;
      if (location.search) {
        const token = new URLSearchParams(location.search).get("token");
        const block = new URLSearchParams(location.search).get("block");
        this.getCurrentBlockAndCurrentTask(token, block);
      } else {
        this.setState({ errorShow: true });
      }
    }

    if (
      (prevState.valueHTML !== valueHTML && clientWidth > 900) ||
      (prevState.valueCSS !== valueCSS && clientWidth > 900) ||
      (prevState.currentTaskId !== currentTaskId && clientWidth > 900)
    ) {
      shouldAvailabilityAutotranslator = true;
      availabilityAutotranslatorTimer = waitTimeInMs;
    }
  }

  componentWillUnmount() {
    clearInterval(timerAvailabilityAutotranslatorId);
  }

  hideTimer = () => {
    this.setState({ showTimer: false });
  };

  forHighligthExampleInTheoryAndTaskSection = () => {
    document.querySelectorAll("pre code").forEach((block) => {
      window.hljs.highlightBlock(block);
    });
  };

  handleChangeHMTL = (editor, data, value) => {
    this.setState({ valueHTML: value });
  };

  handleChangeCSS = (editor, data, value) => {
    this.setState({ valueCSS: value });
  };

  getCurrentBlockAndCurrentTask = (token, block) => {
    API.getTasksBlockAndCurrentTask(token, block)
      .then((res) => {
        // console.log("getCurrentBlockAndCurrentTask res", res);
        if (res.data.success) {
          //если юзер не участник марафона будет заглушка
          // if (res.data.userInMarathon) {
          this.setState(
            {
              currentTaskId: res.data.currentTask,
              blockTasksArr: res.data.blockTasks,
              token,
              blockIndex: res.data.blockIndex,
              errorInvalidTokenOrBlock: false,
              errorShow: false,
              isServerError: false,
              passedTasks: res.data.passedTasks,
            },
            () => {
              this.getTask();
            }
          );
          // } else {
          //   this.setState({ errorShow: true });
          // }
        } else {
          this.setState({ errorInvalidTokenOrBlock: true });
          toast.error(res.data.message, {
            autoClose: 4000,
          });
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      });
  };

  getTask = () => {
    const { token, currentTaskId } = this.state;
    toast.dismiss();

    this.setState({ showLoader: true });
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        // console.log("fetch res ", res);
        this.setState(
          {
            valueHTML: res.data.initialHtml,
            valueCSS: res.data.initialCss,
            htmlDescription: res.data.htmlDescription,
            metadata: res.data.metadata,
            passed: res.data.passed,
            startTime: Date.now(),
            isServerError: false,
          },
          () => {
            this.forHighligthExampleInTheoryAndTaskSection();
            this.callToastify();
          }
        );
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  checkTest = () => {
    const { token, currentTaskId, valueHTML, valueCSS, startTime } = this.state;
    const solveTimeSeconds = Math.round((Date.now() - startTime) / 1000);

    this.setState({ showLoader: true });
    API.checkTest(token, currentTaskId, valueHTML, valueCSS, solveTimeSeconds)
      .then((res) => {
        // console.log("checkTest res ", res);
        this.setState({
          successConditions: res.data.successConditions,
          failedConditions: res.data.failedConditions,
          taskSuccessfullyPassed: res.data.taskSuccessfullyPassed,
          isServerError: false,
        });
      })
      .then(() => {
        const {
          blockTasksArr,
          currentTaskId,
          taskSuccessfullyPassed,
        } = this.state;
        const isLastDayQuestion =
          blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1
            ? true
            : false;
        // console.log("isLastDayQuestion ", isLastDayQuestion);
        // console.log("taskSuccessfullyPassed  ", taskSuccessfullyPassed);
        if (isLastDayQuestion && taskSuccessfullyPassed) {
          this.openCongratsModal();
        } else {
          this.setState({ isResultModalOpen: true });
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  closeResultModal = () => this.setState({ isResultModalOpen: false });

  openCongratsModal = () => this.setState({ isCongratsModalOpen: true });
  closeCongratsModal = () => this.setState({ isCongratsModalOpen: false });

  getNextTask = () => {
    toast.dismiss();
    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== blockTasksArr.length - 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) + 1]
        : blockTasksArr[0];

    this.setState((prevState) => ({
      showLoader: true,
      passedTasks: prevState.passedTasks.includes(currentTaskId)
        ? [...prevState.passedTasks]
        : [...prevState.passedTasks, currentTaskId],
    }));
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        this.setState(
          {
            currentTaskId: res.data.taskId,
            valueHTML: res.data.initialHtml,
            valueCSS: res.data.initialCss,
            htmlDescription: res.data.htmlDescription,
            metadata: res.data.metadata,
            passed: res.data.passed,
            timerEndTime: Date.now() + timerDelayInMs,
            showTimer: true,
            startTime: Date.now(),
            isServerError: false,
          },
          () => {
            this.forHighligthExampleInTheoryAndTaskSection();
            this.callToastify();
          }
        );
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  getPrevTask = () => {
    toast.dismiss();
    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) - 1]
        : blockTasksArr[0];

    this.setState({ showLoader: true });
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        this.setState(
          {
            currentTaskId: res.data.taskId,
            valueHTML: res.data.initialHtml,
            valueCSS: res.data.initialCss,
            htmlDescription: res.data.htmlDescription,
            metadata: res.data.metadata,
            passed: res.data.passed,
            timerEndTime: Date.now() + timerDelayInMs,
            showTimer: true,
            startTime: Date.now(),
            isServerError: false,
          },
          () => {
            this.forHighligthExampleInTheoryAndTaskSection();
            this.callToastify();
          }
        );
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  toggleTheoryAndTask = () => {
    this.setState(
      (prevState) => ({
        isTheoryAndTaskShow: !prevState.isTheoryAndTaskShow,
      }),
      () => this.forHighligthExampleInTheoryAndTaskSection()
    );
  };

  callToastify = () => {
    const { passed, metadata } = this.state;
    if (passed) {
      toast.success("Эта задача уже решена!", {
        autoClose: 4000,
      });
    }
    if (metadata.disableHtmlEditor) {
      toast.warn(
        "В этом задании HTML редактор не активен, используй только CSS",
        {
          autoClose: 8000,
        }
      );
    }
    if (metadata.disableCssEditor && metadata.needCss) {
      toast.warn(
        "В этом задании CSS редактор не активен, используй только HTML",
        {
          autoClose: 8000,
        }
      );
    }
  };

  refreshCurrentQuestion = () => {
    const { token, currentTaskId } = this.state;
    toast.dismiss();

    this.setState({ showLoader: true });
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        this.setState(
          {
            valueHTML: res.data.initialHtml,
            valueCSS: res.data.initialCss,
            htmlDescription: res.data.htmlDescription,
            metadata: res.data.metadata,
            passed: res.data.passed,
            isServerError: false,
          },
          () => {
            this.forHighligthExampleInTheoryAndTaskSection();
          }
        );
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  openBrowserResultModal = () => {
    this.setState({ showBrowserResultModal: true });
  };

  clodeBrowserResultModal = () => {
    this.setState({ showBrowserResultModal: false });
  };

  changeBackgroundInCodemirrorWithHtml = () => {
    const { metadata } = this.state;
    if (metadata && metadata.disableHtmlEditor) {
      // console.log("#html_box work disable");
      if (!document.querySelector("#html_box")) return;
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 0.9)";
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 0.9)";
    } else if (metadata && !metadata.disableHtmlEditor) {
      // console.log("#html_box work active");
      if (!document.querySelector("#html_box")) return;
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
      document
        .querySelector("#html_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
    }

    if (metadata && metadata.disableCssEditor) {
      // console.log("#css_box work disable");
      if (!document.querySelector("#css_box")) return;
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 0.9)";
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 0.9)";
    } else if (metadata && !metadata.disableCssEditor) {
      // console.log("#css_box work active");
      if (!document.querySelector("#css_box")) return;
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
      document
        .querySelector("#css_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
    }
  };

  addEventPreventDefaultForLinkInResultContainer = () => {
    const { currentTaskId } = this.state;
    // console.log("currentTaskId ", currentTaskId);
    if (
      currentTaskId === "css-36" ||
      currentTaskId === "css-netlify" ||
      currentTaskId === "css-auto-hw-8-4"
    )
      return;
    document
      .querySelector("iframe")
      .contentDocument.querySelectorAll("a")
      .forEach((el) =>
        el.addEventListener("click", function (e) {
          e.preventDefault();
          return false;
        })
      );
  };

  getTotalProgress = () => {
    const {
      token,
      // finishedLastDay
    } = this.state;
    // if (!finishedLastDay) return;
    // this.setState({ showLoader: true });
    API.getTotalProgress(token)
      .then((res) => {
        // console.log("res ", res);
        this.setState({
          nonFinishedDays: Object.entries(res.data.nonFinishedDays),
          finishedLastDay: res.data.finishedLastDay,
          passedAllTasks: res.data.passedAllTasks,
          isServerError: false,
        });
      })
      .catch(() => {
        this.setState({ isServerError: true });
      });
    // .finally(() => this.setState({ showLoader: false }));
  };

  getSpecificTask = (taskId) => {
    toast.dismiss();
    const { token } = this.state;

    this.setState({ showLoader: true });
    API.getTaskInfo(token, taskId)
      .then((res) => {
        this.setState(
          {
            currentTaskId: res.data.taskId,
            valueCode: res.data.initialCode,
            htmlDescription: res.data.htmlDescription,
            metadata: res.data.metadata,
            passed: res.data.passed,
            timerEndTime: Date.now() + timerDelayInMs,
            showTimer: true,
            startTime: Date.now(),
            isServerError: false,
            notEditableCodeBlocks: res.data.notEditableJsBlocks,
            codeHlLines: res.data.codeHlLines,
            codeCursor: res.data.codeCursor,
          },
          () => {
            this.forHighligthExampleInTheoryAndTaskSection();
            this.callToastify();
          }
        );
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  checkAvailabilityAutotranslator = () => {
    if (
      !this.headerTextLogoRef.current ||
      !this.headerTextTaskRef.current ||
      !this.footerTextRef.current
    )
      return;

    if (
      !this.headerTextLogoRef.current.textContent.includes(
        "Марафон HTML/CSS"
      ) ||
      !this.headerTextTaskRef.current.textContent.includes("Задание") ||
      !this.footerTextRef.current.textContent.includes(
        "Политика конфиденциальности"
      )
    ) {
      this.openModalAutotranslator();
    }
  };

  openModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: true });
  };
  closeModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: false });
  };

  render() {
    // если не работает сервер, заглушка
    const { isServerError } = this.state;
    if (isServerError) {
      return <ErrorServerStub />;
    }

    // если не валиден token или block:
    const { errorInvalidTokenOrBlock } = this.state;
    if (errorInvalidTokenOrBlock) {
      return (
        <>
          <ToastContainer />
          <ErrorInvalidTokenOrBlock />
        </>
      );
    }

    // Если в url нету query parameters:
    const { errorShow } = this.state;
    if (errorShow) {
      return <ErrorStub />;
    }

    //  меняем беграунд html редактора когда он должен быть неактивным на редактирование
    const { metadata } = this.state;
    const { clientWidth } = this.props;
    if (
      metadata &&
      (metadata.disableHtmlEditor || metadata.disableCssEditor) &&
      clientWidth > 900
    ) {
      this.changeBackgroundInCodemirrorWithHtml();
    }

    const {
      valueHTML,
      valueCSS,
      blockTasksArr,
      currentTaskId,
      // для модального окна с результатами:
      isResultModalOpen,
      successConditions,
      failedConditions,
      taskSuccessfullyPassed,
      passed,
      // isTheoryAndTaskShow
      isTheoryAndTaskShow,
      isCongratsModalOpen,
      resultView,
      // время для таймера:
      timerEndTime,
      showTimer,
      // номер дня марафона в хедере
      blockIndex,
      showBrowserResultModal,
      nonFinishedDays,
      finishedLastDay,
      passedAllTasks,
      showLoader,
      htmlDescription,
      passedTasks,
      showModalAutotranslator,
    } = this.state;
    const widthCodeAndResultSection = isTheoryAndTaskShow
      ? metadata.needCss
        ? "67%"
        : "57%"
      : "94%";
    const widthTheoryAndTaskSection = metadata.needCss ? "30%" : "40%";
    const marginLeftCodeAndResultSection = isTheoryAndTaskShow ? "3%" : "6%";

    const widthHtmlBox = !metadata.needCss ? "100%" : "59%";
    const widthCssBox = "40%";
    return (
      <>
        <Header
          blockTasksArr={blockTasksArr}
          currentTaskId={currentTaskId}
          getPrevTask={this.getPrevTask}
          getNextTask={this.getNextTask}
          passed={passed}
          blockIndex={blockIndex}
          passedTasks={passedTasks}
          getSpecificTask={this.getSpecificTask}
          headerTextLogoRef={this.headerTextLogoRef}
          headerTextTaskRef={this.headerTextTaskRef}
        />
        <main className={styles.main}>
          <article className={styles.mainContent}>
            <ButtonHideTheory
              toggleTheoryAndTask={this.toggleTheoryAndTask}
              isTheoryAndTaskShow={isTheoryAndTaskShow}
            />

            {/* начало секции теории и задания */}
            {isTheoryAndTaskShow && (
              <section
                ref={this.theoryAndTaskRef}
                style={{ width: widthTheoryAndTaskSection }}
              >
                <TheoryAndTaskOrLayout
                  isServerError={isServerError}
                  isTheoryAndTaskShow={isTheoryAndTaskShow}
                  htmlDescription={htmlDescription}
                  cvLink={metadata.cvLink}
                />
              </section>
            )}
            {/* конец секции теории и задания */}

            {/* начало секции кода и результата */}
            <section
              className={styles.codeAndResult_container}
              style={{
                width: widthCodeAndResultSection,
                marginLeft: marginLeftCodeAndResultSection,
              }}
            >
              <div className={styles.code_container}>
                <div className={styles.box} style={{ width: widthHtmlBox }}>
                  <h4 className={styles.codeTitle}>index.html:</h4>
                  <div className={styles.containerHTML} id="html_box">
                    {metadata && metadata.disableHtmlEditor ? (
                      <CodeMirror
                        options={codeMirrorCssSettings.optionsHMTLdisable}
                        value={valueHTML}
                        onBeforeChange={this.handleChangeHMTL}
                      />
                    ) : (
                      <CodeMirror
                        options={codeMirrorCssSettings.optionsHMTL}
                        value={valueHTML}
                        onBeforeChange={this.handleChangeHMTL}
                      />
                    )}
                  </div>
                </div>

                {metadata.needCss && (
                  <div className={styles.box} style={{ width: widthCssBox }}>
                    <h4 className={styles.codeTitle}>styles.css:</h4>
                    <div className={styles.containerCSS} id="css_box">
                      {metadata && metadata.disableCssEditor ? (
                        <CodeMirror
                          value={valueCSS}
                          options={codeMirrorCssSettings.optionsCSSdisable}
                          onBeforeChange={this.handleChangeCSS}
                        />
                      ) : (
                        <CodeMirror
                          value={valueCSS}
                          options={codeMirrorCssSettings.optionsCSS}
                          onBeforeChange={this.handleChangeCSS}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>

              <TaskButtons
                checkTest={this.checkTest}
                refreshCurrentQuestion={this.refreshCurrentQuestion}
                timerEndTime={timerEndTime}
                metadata={metadata}
                // для анимации первого дня первого вопроса
                blockIndex={blockIndex}
                currentTaskId={currentTaskId}
                blockTasksArr={blockTasksArr}
                showTimer={showTimer}
                hideTimer={this.hideTimer}
                clientWidth={clientWidth}
                isTheoryAndTaskShow={isTheoryAndTaskShow}
              />

              {/* <h4 className={styles.result_title}>Результат:</h4> */}
              <div ref={this.iframeRef} className={styles.result_container}>
                <BrowserHeader
                  openBrowserResultModal={this.openBrowserResultModal}
                  refreshCurrentQuestion={this.refreshCurrentQuestion}
                />
                <FrameComponent>
                  <div>
                    <style>{valueCSS}</style>
                    <div dangerouslySetInnerHTML={resultView} />
                  </div>
                </FrameComponent>
              </div>
            </section>
            {/* конец секции кода и результата */}
          </article>
        </main>
        <Footer footerTextRef={this.footerTextRef} />

        {isResultModalOpen && (
          <ResultModal
            onClose={this.closeResultModal}
            successConditions={successConditions}
            failedConditions={failedConditions}
            taskSuccessfullyPassed={taskSuccessfullyPassed}
            getTask={this.getTask}
            getNextTask={this.getNextTask}
            openCongratsModal={this.openCongratsModal}
            blockTasksArr={blockTasksArr}
            currentTaskId={currentTaskId}
          />
        )}

        {isCongratsModalOpen && (
          <CongratsModal
            onClose={this.closeCongratsModal}
            blockTasksArr={blockTasksArr}
            blockIndex={blockIndex}
            getTotalProgress={this.getTotalProgress}
          />
        )}

        {showBrowserResultModal && (
          <BrowserResultModal
            onClose={this.clodeBrowserResultModal}
            refreshCurrentQuestion={this.refreshCurrentQuestion}
            valueCSS={valueCSS}
            resultView={resultView}
          />
        )}

        <ToastContainer />

        {finishedLastDay && !passedAllTasks && (
          <ModalUnresolvedDays nonFinishedDays={nonFinishedDays} />
        )}

        {showLoader && <Loader />}

        {finishedLastDay && passedAllTasks && <ModalFinishGiveArchive />}

        {showModalAutotranslator && (
          <ModalAutotranslator onClose={this.closeModalAutotranslator} />
        )}
      </>
    );
  }
}

export default MainPage;
