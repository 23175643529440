import React, { useState } from "react";
import s from "./FooterResultPage.module.css";

import { ReactComponent as Telegram } from "../../assets/icons/socialcons/telegram.svg";
import { ReactComponent as Instagram } from "../../assets/icons/socialcons/instagram.svg";
import { ReactComponent as Youtube } from "../../assets/icons/socialcons/youtube.svg";
import { ReactComponent as Facebok } from "../../assets/icons/socialcons/facebook.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo/goiteens_logo.svg";
import { ReactComponent as Envelope } from "../../assets/icons/results/envelope_v2.svg";
import { ReactComponent as Communications } from "../../assets/icons/results/communications_v2.svg";

import ModalPolitics from "../ModalPolitics/ModalPolitics";

const FooterResultPage = () => {
  const [isModalPoliticsOpen, setIsModalPoliticsOpen] = useState(false);
  const openModalPolitics = () => setIsModalPoliticsOpen(true);
  const closeModalPolitics = () => setIsModalPoliticsOpen(false);
  return (
    <footer className={s.footer}>
      <section className={s.firstPart}>
        <div className={s.contentFirstPart}>
          <div className={s.info}>
            <a
              className={s.logo}
              href="https://goiteens.ua/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo width="90" height="30" />
            </a>

            <ul className={s.contacts}>
              <li className={s.contactsText}>
                <Envelope className={s.envelopeIcon} />
                <a href="mailto:info@goit.ua">contact@goiteens.ua</a>
              </li>
              <li className={s.contactsText}>
                <Communications className={s.communicationsIcon} />
                <a href="tel:+380933432127">+38 093 343 21 27</a>
              </li>
            </ul>
          </div>

          <a
            href="https://t.me/devofteens"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Telegram className={s.icon} />
          </a>
          <a
            href="https://www.instagram.com/go_iteens/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram className={s.icon} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCePQwxtFDx2ypM-oTh7gqMQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Youtube className={s.icon} />
          </a>
          <a
            href="https://www.facebook.com/GoITeens"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebok className={s.icon} style={{ marginRight: "0" }} />
          </a>
        </div>
      </section>

      <section className={s.secondPart}>
        <p className={s.privacyPolicy}>
          <span className={s.privacyPolicySpan} onClick={openModalPolitics}>
            Политика конфиденциальности
          </span>
        </p>
      </section>

      {isModalPoliticsOpen && <ModalPolitics onClose={closeModalPolitics} />}
    </footer>
  );
};

export default FooterResultPage;
