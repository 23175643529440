import React from "react";
import s from "./ErrorServerStub.module.css";
import logo from "../../assets/icons/logo/goiteens_logo.svg";

const ErrorStub = () => {
  return (
    <div className={s.container}>
      <img width="90" height="30" src={logo} alt="logo" />
      <p className={s.text}>
        Технические работы на сервере, попробуйте снова позже.
      </p>
    </div>
  );
};

export default ErrorStub;
