import axios from "axios";

axios.defaults.baseURL = "https://gotest.com.ua/api/v2/";

export const getTasksBlockAndCurrentTask = (token, block) =>
  axios
    .get(`taskblock/get?token=${token}&block=${block}&language=ru`)
    .catch((err) =>
      console.warn("api getTasksBlockAndCurrentTask error: ", err)
    );

export const getTaskInfo = (token, taskId) =>
  axios
    .get(`task/getTaskData?token=${token}&taskId=${taskId}&language=ru`)
    .catch((err) => console.warn("api getTaskInfo error: ", err));

export const checkTest = async (token, taskId, html, css, solveTimeSeconds) => {
  var bodyFormData = new FormData();
  bodyFormData.set("token", token);
  bodyFormData.set("taskId", taskId);
  bodyFormData.set("html", html);
  bodyFormData.set("css", css);
  bodyFormData.set("solveTimeSeconds", solveTimeSeconds);
  bodyFormData.set("language", 'ru');
  return axios({
    method: "post",
    url: "https://gotest.com.ua/api/v2/task/check",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).catch((err) => console.warn("api checkTest error: ", err));
};

export const getTotalProgress = (token) =>
  axios
    .get(`taskblock/getTotalProgress?token=${token}`)
    .catch((err) => console.warn("api getTotalProgress error: ", err));
