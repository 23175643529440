import React from "react";
import s from "./ErrorStub.module.css";
import logo from "../../assets/icons/logo/goiteens_logo.svg";

const ErrorStub = () => {
  return (
    <div className={s.container}>
      <img width="90" height="30" src={logo} alt="logo" />
      <p className={s.text}>Не валидная ссылка.</p>
      <p className={s.text}>План действий:</p>
      <p className={s.text}>
        1. Вернитесь в телеграм и попробуйте открыть ссылку снова.
      </p>
      <p className={s.text}>2. Обратитесь к администратору чата за помощью.</p>
    </div>
  );
};

export default ErrorStub;
