import React from "react";
import s from "./MobileAndTabletStub.module.css";
import logo from "../../assets/icons/logo/goiteens_logo.svg";

const MobileAndTabletStub = () => {
  return (
    <div className={s.container}>
      <img width="90" height="30" src={logo} alt="logo" />
      <p className={s.text}>Расширь браузер или зайди с десктопа.</p>
    </div>
  );
};

export default MobileAndTabletStub;
