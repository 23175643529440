import React from "react";
import { ReactComponent as Reset } from "../../assets/icons/autorenew-black-18dp.svg";
import { ReactComponent as Extend } from "../../assets/icons/fullscreen.svg";
import s from "./BrowserHeader.module.css";

const BrowserHeader = ({ refreshCurrentQuestion, openBrowserResultModal }) => {
  return (
    <div className={s.container}>
      <button onClick={refreshCurrentQuestion} className={s.button}>
        <Reset className={s.icon} />
      </button>
      <span className={s.link}>https://goiteens.marathon.html.css/</span>
      <button onClick={openBrowserResultModal} className={s.button}>
        <Extend className={s.icon2} />
      </button>
    </div>
  );
};

export default BrowserHeader;
